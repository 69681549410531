import React from 'react';
import ReactMarkdown from 'react-markdown/with-html';
import { buildImageObj, imageUrlFor } from './sanity/image-url';

export default ({ node }) => {
  return (
    <article className="companies">
      {node.text && <ReactMarkdown className="companies__text" source={node.text} />}
      <div className="companies__logos">
        <div className="row control-break">
          {node.companyLogos && node.companyLogos.map(company => <CompanyLogo key={company._id} company={company} />)}
        </div>
      </div>
    </article>
  );
};

function CompanyLogo({ company }) {
  if (!company || !company.logo || !company.logo.asset) {
    return null;
  }

  return (
    <div className="col-12 col-sm-4 col-lg-2_4">
      <div class="company company-tiny">
        {company.companyUrl ? (
          <a href={company.companyUrl} className="link tile tile--company tile--bw tile--color-hover" title="View Website" target="_blank" rel="noopener noreferrer">
            <figure className="tile__media">
              <img className="logo" src={imageUrlFor(buildImageObj(company.logo))} width="512" height="384" alt={`${company.title} Logo`} />
            </figure>
          </a>
        ) : (
            <div className="link tile tile--company tile--bw tile--color-hover">
              <figure className="tile__media">
                <img className="logo" src={imageUrlFor(buildImageObj(company.logo))} width="512" height="384" alt={`${company.title} Logo`} />
              </figure>
            </div>
          )}
      </div>
    </div>
  );
}
