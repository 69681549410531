import React from 'react';
import ReactMarkdown from 'react-markdown/with-html';

export default ({ node }) => {
  return (
    <div className="row p-top p-bot row--hr-bot headline-aux">
      <div className={'col-sm-12' + (node.centerText ? ' text-center' : '')}>
        <header>
          <h1>
            <strong className="headline__headline">{node.headline}</strong>
          </h1>
        </header>
        {node.text && <ReactMarkdown className="headline__p" source={node.text} escapeHtml={false} />}
        {node.subpoints && (
          <div className="row p-top">
            {node.subpoints.map(subpoint => (
              <div key={subpoint.subpoint} className="col-sm-10 offset-sm-1 col-md-8 offset-md-2 col-lg-6 offset-lg-3 text-left p-bot">
                <div className="subpoint">
                  <ReactMarkdown source={subpoint.subpoint} />
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};
