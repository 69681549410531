import React from 'react';
import { Link } from 'gatsby';
import { graphql } from 'gatsby';
import _ from 'lodash';
import SEO from '../components/seo';
import Wrapper from '../containers/wrapper';
import { buildImageObj, imageUrlFor } from '../components/sanity/image-url';

import ChapterSection from '../components/chapter-section';
import HeadlineSection from '../components/headline-section';
import MarkdownSection from '../components/markdown-section';
import CompaniesSection from '../components/companies-section';
import TeamSection from '../components/team-section';

export const query = graphql`
query BasicSectionsTemplateQuery($id: String!) {
  basic: sanityBasicSections(id: { eq: $id }) {
      id
      publishedAt
      title
      slug {
        current
      }
      image {
        asset {
          _id
        }
      }
      hideTitle
			widePage
			pageMenu {
				link
				title
			}
      _rawSections(resolveReferences: { maxDepth: 5 })
    }
  }
`;

const sectionTypes = {
  markdownSection: MarkdownSection,
  chapterSection: ChapterSection,
  headlineSection: HeadlineSection,
  companiesSection: CompaniesSection,
  teamSection: TeamSection
};


const BasicSectionsPage = props => {
  const { data } = props;
  const page = _.get(data, 'basic');
  const pageMenu = _.get(data, 'basic.pageMenu', []);

  if (!page) {
    throw new Error('Missing data');
  }

  const sections = _.get(page, '_rawSections');
  return (
    <Wrapper>
      <SEO title={page.title} />
      <div className="container">
        <div className="row justify-content-start">
          <div className="col-12">
            <WrapDiv condition={!page.widePage}>
              {!page.hideTitle && (
                <header className="page-heading">
                  <h1 className="page-heading__title">{page.title}</h1>
                </header>
              )}

              <ul className="nav-tabs nav-tabs--tight-h">
                {pageMenu.map(menu => (
                  <li>
                    <Link to={menu.link} activeClassName="active">{menu.title}</Link>
                  </li>

                ))}
              </ul>

              {page.image && page.image.asset && (
                <div className="col-12 mb-4">
                  <img src={imageUrlFor(buildImageObj(page.image)).width(1200)} />
                </div>
              )}

              {sections &&
                sections.map(item => {
                  let Component = sectionTypes[item._type];
                  return <Component key={item._key} node={item} />;
                })}
            </WrapDiv>
          </div>
        </div>
      </div>
    </Wrapper>
  );
};

function WrapDiv({ condition, children }) {
  if (!condition) {
    return children;
  }
  return <div className="col-md-10 offset-md-1 col-lg-8 offset-lg-2">{children}</div>;
}

export default BasicSectionsPage;
