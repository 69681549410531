import React from 'react';
import ReactMarkdown from 'react-markdown/with-html';
import LinkedinIcon from '../images/icons/linkedin-icon.svg';
import { buildImageObj, imageUrlFor } from './sanity/image-url';

export default ({ node }) => {
  return (
    <article className="team">
      {node.text && <ReactMarkdown className="team__text" source={node.text} />}
      <div className="team__members">
        <div className="row">
          {node.teamMembers && node.teamMembers.map(person => <TeamMember key={person._id} person={person} />)}
        </div>
      </div>
    </article>
  );
};

function TeamMember({ person }) {
  if (!person || !person.previewImage || !person.previewImage.asset) {
    return null;
  }

  return (
    <div className="col-12 col-sm-6 col-md-4 col-lg-3">
      <div className="tile tile--bw tile--color-hover">
        <figure className="tile__media">
        <a href={person.linkedinUrl} className="tile__link">
          <img
            className="tile__img lazy"
            src={imageUrlFor(buildImageObj(person.previewImage))
              .width(576)
              .height(334)
            }
            alt={person.previewImage.alt}
          />
        </a>
        </figure>
        {person.imageLogo && person.imageLogo.asset && (
        <img
          className="tile__img logo-svg"
          src={imageUrlFor(buildImageObj(person.imageLogo))}
          alt={person.imageLogo.alt}
        />
        )}
        <div className="tile__body">
          <a href={person.linkedinUrl} className="tile__link">
          <strong className="tile__title">{person.name}</strong>
        </a>
        <div className="tile__subtitle">{person.education}</div>
        <a href={person.linkedinUrl} className="tile__link">
          <LinkedinIcon />
        </a>

        </div>
      </div>
    </div>
  );
}
