import React from 'react';
import ReactMarkdown from 'react-markdown/with-html';
import { buildImageObj, imageUrlFor } from './sanity/image-url';

export default ({ node }) => {
  return (
    <article className="chapter">
      {node.chapterText && <ReactMarkdown className="chapter__text" source={node.chapterText} />}
      <div className="chapter__logos">
        <div className="row justify-content-center">
          {node.chapterLogos && node.chapterLogos.map(company => <CompanyLogo key={company._id} company={company} />)}
        </div>
      </div>
    </article>
  );
};

function CompanyLogo({ company }) {
  if (!company || !company.logo || !company.logo.asset) {
    return null;
  }

  return (
    <div className="col-6 col-sm-4 col-md-3">
      <div className="tile tile--company tile--bw tile--color-hover">
        <figure className="tile__media">
          <img className="tile__img" src={imageUrlFor(buildImageObj(company.logo))} width="512" height="384" alt={`${company.title} Logo`} />
        </figure>
      </div>
    </div>
  );
}
